import {
	resetRouter
} from "@/router/index";
import {
	checkMobile
} from "@/utils/index";

export default {
	name: "Login",
	watch: {},
	data() {
		let checkUserName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("用户名不能为空"));
			}
			callback();
		};
		let validatePass = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("密码不能为空"));
			}
			callback();
		};

		return {
			loginForm: {
				password: null,
				passEncode: '',
				userName: null
			},
			isRemember: false,
			rules: {
				password: [{
					validator: validatePass,
					trigger: "blur"
				}],
				userName: [{
					validator: checkUserName,
					trigger: "blur"
				}],
			},
			checkImg: require('../../assets/images/checkBg.png'),
			checkedImg: require('../../assets/images/checkedBg.png'),
			showImg: null,

			dialogPassword: false,
			passForm: {
				mobile: '',
				code: '',
				password: '',
				passwordCom: ''
			},
			signinForm: {
				username: null,
				mobile: null,
				code: null,
				password: null,
				passwordCom: '',
			},
			valiBtn: '获取验证码',
			valiBtnSignin: '获取验证码',
			disabled: false,
			getPatientDetailUuid: '',
			dialogSignIn: false,
			ufu: null,
			secretUfu: null,
			patientname: null,
			patientShow: false,
			passwordTime: true,
			signinTime: true,
			arr:[]
		}
	},
	created() {
		this.showImg = this.checkImg;
	},
	mounted() {
		// 页面加载的时候，首先去查看一下cookie中有没有用户名和密码可以用
		this.getCookie();
		this.secretUfu = this.$route.query.ufu;
		console.log(this.secretUfu)
		this.getufu();
	},
	methods: {
		usernamePaste(event) {
			let text = (event.clipboardData || window.clipboardData).getData('text');
			this.arr = text.split(/ [(\r\n)\r\n] +/); // 以转行符切割文本字符串
		},
		passwordFocus(){
			this.loginForm.passEncode=null;
			this.loginForm.password =null;
		},
		encodePass() {
			let forEn = this.loginForm.passEncode;
			this.loginForm.password = forEn;
			/*var count = 0;
			for (var i = 0; i < forEn.length; i++) {
				if (forEn.slice(i, i + 1) == '·') {
					count = count + 1
				}
			}
			if(this.loginForm.password !== null && count == this.loginForm.password.length){
				this.loginForm.password = this.loginForm.password + forEn.slice(count,forEn.length)
			}else{
				this.loginForm.password = forEn;
			}
			 if((count+1)==forEn.length){
				if(forEn.length == 1){
					this.loginForm.password = forEn
				}else{
					this.loginForm.password =  this.loginForm.password + forEn.slice(-1);
				}
			}else if(forEn.slice(count,-1) !== null && this.loginForm.password !==null&&this.arr.length ==0){
				this.loginForm.password = this.loginForm.password + forEn.slice(count,-1)
			}else if(this.loginForm.password == null&&this.arr.length ==0){
				this.loginForm.password = forEn.slice(count,-1)
			}else if(this.loginForm.password == null&&this.arr.length !==0){
				this.loginForm.password = this.loginForm.passEncode
			}else if(this.arr.length !==0&&forEn.length<this.loginForm.password.length){
				this.loginForm.password = this.loginForm.passEncode
			}else{
				this.loginForm.password = this.loginForm.password + this.arr[0]
			} */
			let starStr = '';
			for (let i = 0; i < forEn.length; i++) {
				starStr = starStr + '·';
			}
			this.arr.splice(0, this.arr.length); //清空数组
			this.loginForm.passEncode = starStr;
			console.log(this.loginForm.password )
		},
		//分享连接id解密
		getufu() {
			if (this.secretUfu !== null && this.secretUfu !== undefined) {
				this.ufu = this.secretUfu.slice(0, this.secretUfu.length - 3);
				this.getSharer();
			}
		},
		//查询分享者的用户信息
		getSharer() {
			console.log(this.ufu)
			if (this.ufu !== '' || this.ufu !== null) {
				this.$axios
					.get("/api/web/user/detail/" + this.ufu)
					.then(response => {
						if (response.data.code === 200) {
							this.patientShow = true
							if (response.data.data.name !== null) {
								this.patientname = response.data.data.name;
							} else {
								this.patientname = response.data.data.account
							}
						}
					})
			}
		},
		//忘记密码提交
		onPassSubmit() {
			if (this.passForm.mobile === null || this.passForm.mobile === '') {
				this.$message({
					message: '请输入手机号',
					type: "error"
				});
				return;
			} else {
				if (!checkMobile(this.passForm.mobile)) {
					this.$message({
						message: '手机号不合法',
						type: "error"
					});
					return;
				}
			}

			if (this.passForm.code === null || this.passForm.code === '') {
				this.$message({
					message: '请输入验证码',
					type: "error"
				});
				return;
			}

			if (this.passForm.password === null || this.passForm.password === '') {
				this.$message({
					message: '请输入新密码',
					type: "error"
				});
			}

			if (this.passForm.passwordCom === null || this.passForm.passwordCom === '') {
				this.$message({
					message: '请输入新密码',
					type: "error"
				});
				return;
			}

			if (this.passForm.password !== this.passForm.passwordCom) {
				this.$message({
					message: '两次密码输入不一致',
					type: "error"
				});
				return;
			}

			if (this.passForm.password === this.passForm.passwordCom && this.passForm.passwordCom !== null && this
				.passForm.passwordCom !== '') {
				var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
				if (reg.test(this.passForm.password) && this.passForm.password.length >= 8) {
					console.log(this.passForm.password)
				} else {
					this.$message({
						message: '密码为大于八位，必须有大写字母小写字母和数字',
						type: "error"
					});
					return;
				}
			}
			let paramD = {
				mobile: this.passForm.mobile,
				password: this.passForm.password,
				passwordCom: this.passForm.passwordCom,
				verifyCode: this.passForm.code
			};
			console.log(paramD)
			this.$axios({
				method: "post",
				url: "/api/web/user/forgetPassword",
				data: paramD
			}).then(res => {
				console.log(res.data)
				if (res.data.code === 200) {
					this.$message({
						message: '修改成功！',
						type: "success"
					});
					this.dialogPassword = true;
				} else {
					this.$message({
						message: res.data.message,
						type: "error"
					});
				}
			});
		},
		//注册表单提交
		onSigninSubmit() {
			if (this.signinForm.username === null || this.signinForm.username === '') {
				this.$message({
					message: '请输入用户名',
					type: "error"
				});
				return;
			}

			if (this.signinForm.mobile === null || this.signinForm.mobile === '') {
				this.$message({
					message: '请输入手机号',
					type: "error"
				});
				return;
			} else {
				if (!checkMobile(this.signinForm.mobile)) {
					this.$message({
						message: '手机号不合法',
						type: "error"
					});
					return;
				}
			}

			if (this.signinForm.code === null || this.signinForm.code === '') {
				this.$message({
					message: '请输入验证码',
					type: "error"
				});
				return;
			}

			if (this.signinForm.password === null || this.signinForm.password === '') {
				this.$message({
					message: '请输入密码',
					type: "error"
				});
				return;
			} else {
				var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
				if (reg.test(this.signinForm.password) && this.signinForm.password.length >= 8) {
					console.log(this.signinForm.password)
				} else {
					this.$message({
						message: '密码为大于八位，必须有大写字母小写字母和数字',
						type: "error"
					});
					return;
				}
			}

			if (this.signinForm.passwordCom === null || this.signinForm.passwordCom === '') {
				this.$message({
					message: '请再次输入密码',
					type: "error"
				});
				return;
			}

			if (this.signinForm.password !== this.signinForm.passwordCom) {
				this.$message({
					message: '两次密码输入不一致',
					type: "error"
				});
				return;
			}

			let params = {
				account: this.signinForm.username,
				mobile: this.signinForm.mobile,
				password: this.signinForm.password,
				verifyCode: this.signinForm.code
			};
			console.log(params)
			this.$axios({
				method: "post",
				url: "/api/web/register/register",
				data: params
			}).then(res => {
				if (res.data.code === 200) {
					this.$message({
						message: '注册成功！',
						type: "success"
					});
					this.dialogSignIn = false;
				} else {
					this.$message({
						message: res.data.message,
						type: "error"
					});
				}
			});
		},
		//忘记密码手机验证
		sendCode() {
			if (this.passwordTime) {
				this.passwordTime = false;
				setTimeout(() => {
					this.passwordTime = true;
				}, 1000)
			}
			if (this.passForm.mobile === null || this.passForm.mobile === '') {
				this.$message({
					message: '请输入手机号',
					type: "error"
				});
				return;
			} else {
				if (!checkMobile(this.passForm.mobile)) {
					this.$message({
						message: '手机号不合法',
						type: "error"
					});
					return;
				}
			}
			this.tackBtn(2);
			this.$axios
				.get("/api/web/sms/send/" + this.passForm.mobile)
				.then(res => {
					if (res.data.code === 200) {
						this.$message({
							message: '验证码发送成功',
							type: "success"
						});
					}
				});
		},
		//注册页面手机验证
		sendCodeSignin() {
			if (this.signinTime) {
				this.signinTime = false;
				setTimeout(() => {
					this.signinTime = true;
				}, 1000)
			}
			console.log(this.signinForm.mobile)
			if (this.signinForm.mobile === null || this.signinForm.mobile === '') {
				this.$message({
					message: '请输入手机号',
					type: "error"
				});
				return;
			} else {
				if (!checkMobile(this.signinForm.mobile)) {
					this.$message({
						message: '手机号不合法',
						type: "error"
					});
					return;
				}
			}
			this.tackBtn(1);
			this.$axios
				.get("/api/web/sms/send/" + this.signinForm.mobile)
				.then(res => {
					if (res.data.code === 200) {
						this.$message({
							message: '验证码发送成功',
							type: "success"
						});
					}
				});
		},
		tackBtn(e) { //验证码倒数60秒
			let time = 60;
			let timer = setInterval(() => {
				if (time == 0) {
					clearInterval(timer);
					this.valiBtn = '获取验证码';
					this.disabled = false;
				} else if (time !== 0 && e == 1) {
					this.disabled = true;
					this.valiBtnSignin = time + '秒后重试';
					this.signinTime = false;
					time--;
				} else if (time !== 0 && e == 2) {
					this.disabled = true;
					this.valiBtn = time + '秒后重试';
					this.passwordTime = false;
					time--;
				}
			}, 1000);
		},
		changeCheck() {
			if (this.showImg === this.checkImg) {
				this.showImg = this.checkedImg;
			} else {
				this.showImg = this.checkImg;
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					const userName = this.loginForm.userName;
					const password = this.loginForm.password;
					const param = {
						account: userName,
						password: password,
						// 固定的，后台dto与app共用导致
						userType: 1
					};
					console.log(param)
					this.$axios({
						method: "post",
						url: "/api/web/user/login",
						data: param
					}).then(res => {
						if (res.data.code !== 200) {
							this.$message({
								message: res.data.message,
								type: "error"
							});
							return;
						}
						//若复选框被勾选了，就调用设置cookie方法，把当前的用户名和密码和过期时间存到cookie中
						if (this.isRemember === true) {
							// 传入账号名，密码，和保存天数（过期时间）3个参数
							this.setCookie(this.loginForm.userName, this.loginForm.password, 7);
						} else {
							//若没被勾选就及时清空Cookie，因为这个cookie有可能是上一次的未过期的cookie，所以要及时清除掉
							this.clearCookie();
						}
						// 获取token并存储在sessionStorage
						sessionStorage.setItem("token", res.data.data.token);
						// 获取当前登录人名称并存储在sessionStorage
						sessionStorage.setItem("username", res.data.data.account);
						// 获取当前登录人account并存储在sessionStorage
						sessionStorage.setItem("userUuid", res.data.data.uuid);
						// 将当前登录人的shiroKey集合存储到sessionStorage
						sessionStorage.setItem("tRoleLists", JSON.stringify(res.data.data.rights));
						// 当前登录人角色集合用于判断 是医生还是健康专员  根据UUID硬判断的
						sessionStorage.setItem("rolesUuid", JSON.stringify(res.data.data.rolesUuid));
						//当前登录人用户类型用于半段 是医生还是患者还是管理员
						sessionStorage.setItem("userType", JSON.stringify(res.data.data.userType));
						//const userType = res.data.data.userType;
						console.log(this.ufu)
						/* if (userType !== 4 && this.ufu !== null) {
							this.$message({
								message: "用戶已存在，请重新注册！",
								type: "error"
							});
						else { */
							// 登录成功后获取菜单接口
							console.log(sessionStorage.getItem("token"))
							this.$axios({
								method: "get",
								url: "/api/web/menu/getMenuList"
							}).then(res1 => {
								console.log("111", res1);
								if (res1.data.code !== 200) {
									this.$message({
										message: res.data.message,
										type: "error"
									});
								}

								const routeData = res1.data.data;
								// 菜单数据
								sessionStorage.setItem(
									"routeData",
									JSON.stringify(routeData)
								);
								console.log(routeData)
								resetRouter();
								console.log(res.data.data)
								// 患者直接进详情
								if (res.data.data.userType === 3) {
									console.log(res.data.data);
									this.$router.push({
										path: "patient-detail",
										query: {
											getPatientDetailUuid: res.data.data.uuid,
											ufu: this.ufu,
										}
									});
								} else if (res.data.data.userType === 2 || res.data.data.userType === 4) {
									console.log(res.data.data.uuid)
									this.$router.push({
										path: "/patient/patient-list",
										query: {
											getPatientDetailUuid: res.data.data.uuid,
											ufu: this.ufu
										}
									});
								} else {
									this.$router.push({
										path: "home",
										query: {
											ufu: this.ufu
										}
									});
								}
							});
						//}

					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});
				} else {
					//console.log("登录失败！");
					return false;
				}
			});
		},
		// 设置cookie
		//setCookie(userName, password, exdays) {
		setCookie(userName, exdays) {
			var exdate = new Date(); // 获取当前登录的时间
			exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 将当前登录的时间加上七天，就是cookie过期的时间，也就是保存的天数
			// 字符串拼接cookie,因为cookie存储的形式是name=value的形式
			window.document.cookie = "userName" + "=" + userName + ";path=/;expires=" + exdate.toGMTString();
			//window.document.cookie = "password" + "=" + password + ";path=/;expires=" + exdate.toGMTString();
			window.document.cookie = "isRemember" + "=" + this.isRemember + ";path=/;expires=" + exdate.toGMTString();
		},
		//若cookie中有用户名和密码的话，就通过两次切割取出来存到loginForm表单中以供使用，若是没有就没有
		getCookie: function() {
			if (document.cookie.length > 0) {
				var arr = document.cookie.split("; "); //因为是数组所以要切割。打印看一下就知道了
				// console.log(arr,"切割");
				for (var i = 0; i < arr.length; i++) {
					var arr2 = arr[i].split("="); // 再次切割
					//console.log(arr2,"切割2");
					// 判断查找相对应的值
					if (arr2[0] === "userName") {
						this.loginForm.userName = arr2[1]; // 转存一份保存用户名和密码
					} else if (arr2[0] === "password") {
						//this.loginForm.password = arr2[1]; //可解密
						this.loginForm.password = ''; //可解密
					} else if (arr2[0] === "isRemember") {
						this.isRemember = Boolean(arr2[1]);
					}
				}
			}
		},
		// 清除cookie
		clearCookie: function() {
			this.setCookie("", "", -1); // 清空并设置天数为负1天
		},
		doFogetPass() {
			this.dialogPassword = true;
		}
	}
}
