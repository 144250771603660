import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Meta from 'vue-meta';

import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios"; // 引入axios
import commonUtil from "./utils/index";
import echarts from "echarts";
// import Chart from "chart.js";

import "./assets/style/common.css";

import JsBridge from "./utils/bridge.js"
Vue.prototype.$jsbridge = JsBridge;

// 加载elementUI，全局设置组件大小
Vue.use(ElementUI, {size: 'small'});
Vue.use(Meta);

import * as commonFilter from "@/filters/filters";
// 全局注册，使用方法为：this.$axios
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
// 引入全局过滤器
axios.interceptors.request.use(config => {
  if (["Login", "Register", "gnrh", "glu", "csii"].indexOf(config.url) === -1) {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  }
});
// axios响应拦截器
axios.interceptors.response.use(
    response => {
      // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
      // 否则的话抛出错误
      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    // 服务器状态码不是2开头的的情况
    // 根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
      if (error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
          case 401:
            Vue.prototype.$message({ message: "身份验证失败，请重新登录", type: "error" });
            // window.location.href = "/";
            // router.push("/")
            break;
            // break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
          case 403:
            Vue.prototype.$message({ message: "登录过期，请重新登录", type: "error" });
            // 清除token
            break;
            // 404请求不存在
          case 404:
            Vue.prototype.$message({ message: "您访问的网页不存在", type: "error" });
            break;
            // 其他错误，直接抛出错误提示
          default:
            Vue.prototype.$message({ message: error.response.data.message, type: "error" });
        }
        return Promise.reject(error.response);
      }
    }
);

Object.keys(commonFilter).forEach(item => {
  Vue.filter(item, commonFilter[item]);
});
// 引入工具类
Vue.use(commonUtil);
// 引入echarts
Vue.use(echarts);
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
