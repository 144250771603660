import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login/login.vue";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

export const constantRoutes = [];
let rootLayout;
const createRouter = () => {
	// 获取当前登录人的shiroKey集合
	let tRoleLists = sessionStorage.getItem("tRoleLists");
	if (tRoleLists) {
		tRoleLists = JSON.parse(tRoleLists);
	} else if (!tRoleLists) {
		tRoleLists = [];
	}

	// 获取路由数据
	let routeData = sessionStorage.getItem("routeData");
	if (routeData) {
		routeData = JSON.parse(routeData);
	} else if (!routeData) {
		routeData = [];
	}

	constantRoutes.splice(0, constantRoutes.length);
	// h5 -- 血糖管理app
	constantRoutes.push({
		path: "/glu/model",
		component: resolve => require(['../views/h5/glu/GluModel.vue'], resolve)
	});
	constantRoutes.push({
		path: "/glu/scoreDetail",
		component: resolve => require(['../views/h5/glu/UserScoreDetail.vue'], resolve)
	});

	// h5 -- app使用
	constantRoutes.push({
		path: "/gnrh",
		component: resolve => require(['../views/h5/gnrh/gnrh.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gnrhMedical",
		component: resolve => require(['../views/h5/gnrh/medical/GnrhMedical.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gmlist",
		component: resolve => require(['../views/h5/gnrh/medical/GnrhMedicalList.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gnrhMedicalDetail",
		component: resolve => require(['../views/h5/gnrh/medical/GnrhMedicalDetail.vue'], resolve)
	});

	constantRoutes.push({
		path: "/gnrhPhysical",
		component: resolve => require(['../views/h5/gnrh/physical/GnrhPhysical.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gnrhPhysicalDetail",
		component: resolve => require(['../views/h5/gnrh/physical/GnrhPhysicalDetail.vue'], resolve)
	})
	constantRoutes.push({
		path: "/gplist",
		component: resolve => require(['../views/h5/gnrh/physical/GnrhPhysicalList.vue'], resolve)
	});

	constantRoutes.push({
		path: "/csiiLifestyleList",
		component: resolve => require(['../views/h5/csii/lifestyle/CsiiLifestyleList.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiiLifestyle",
		component: resolve => require(['../views/h5/csii/lifestyle/CsiiLifestyle.vue'], resolve)
	});
	constantRoutes.push({
		path: "/AddCsiiLifestyleList",
		component: resolve => require(['../views/h5/csii/lifestyle/AddCsiiLifestyleList.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiiLifesMedical",
		component: resolve => require(['../views/h5/csii/medical/CsiiLifesMedical.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiiLifesMedicalList",
		component: resolve => require(['../views/h5/csii/medical/CsiiLifesMedicalList.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiiLifesMedicalDetail",
		component: resolve => require(['../views/h5/csii/medical/CsiiLifesMedicalDetail.vue'], resolve)
	});

	constantRoutes.push({
		path: "/csiignrh",
		component: resolve => require(['../views/h5/csii/csii.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiicaculate",
		component: resolve => require(['../views/h5/csii/csiiCaculate.vue'], resolve)
	});
	constantRoutes.push({
		path: "/",
		component: Login
	});
	constantRoutes.push({
		path: "/changePassword",
		component: resolve => require(['../views/doctor/RelevancePatient.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gnrhsignin",
		component: resolve => require(['../views/h5/gnrh/agreement/GnrhSignIn.vue'], resolve)
	});
	constantRoutes.push({
		path: "/gnrhprivacy",
		component: resolve => require(['../views/h5/gnrh/agreement/GnrhPrivacy.vue'], resolve)
	})
	constantRoutes.push({
		path: "/csiisignin",
		component: resolve => require(['../views/h5/csii/agreement/CsiiSignIn.vue'], resolve)
	});
	constantRoutes.push({
		path: "/csiiprivacy",
		component: resolve => require(['../views/h5/csii/agreement/CsiiPrivacy.vue'], resolve)
	});
	constantRoutes.push({
		path: "/genderinfo",
		component: resolve => require(['../views/h5/gnrh/genderInfo/genderInfo.vue'], resolve)
	});
	constantRoutes.push({
		path: "/genderwoman",
		component: resolve => require(['../views/h5/gnrh/genderInfo/genderwoman.vue'], resolve)
	});
	constantRoutes.push({
		path: "/genderman",
		component: resolve => require(['../views/h5/gnrh/genderInfo/genderman.vue'], resolve)
	});
	constantRoutes.push({
		path: "/docinfo",
		component: resolve => require(['../views/h5/doctorAgreement/DocInfo.vue'], resolve)
	});
	constantRoutes.push({
		path: "/docprivacy",
		component: resolve => require(['../views/h5/doctorAgreement/DocPrivacy.vue'], resolve)
	});
	constantRoutes.push({
		path: "/diabetespreventionpdf",
		component: resolve => require(['../views/h5/csii/diabetesPrevention/diabetesPreventionPdf.vue'],
			resolve)
	});
	rootLayout = {
		path: "/Layout",
		component: () => import("../layout/Layout.vue"),
		children: []
	};
	constantRoutes.push(rootLayout);
	if (tRoleLists && tRoleLists.length > 0) {
		addDynamicRoutes(tRoleLists, routeData, rootLayout.children, null);
	}

	const router = new VueRouter({
		scrollBehavior: () => ({
			y: 1
		}),
		routes: constantRoutes
	});

	router.beforeEach((to, from, next) => {
		let queue = [];
		queue.push(...constantRoutes);
		let exist = false;

		while (queue.length) {
			let item = queue.shift();
			// 存在
			if (to.path === item.path || (to.meta && item.meta && to.meta.uuid === item.meta.uuid)) {
				exist = true;
			}
			if (item.children) {
				queue.push(...item.children);
			}
		}

		if (exist) {
			next();
		} else {
			// window.location.href = '/'
			router.push('/');
			next(false);
		}
	});

	return router;
}

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

/**
 * 添加动态菜单路由
 * @param tRoleLists 角色列表
 * @param roleRouteMapping 对应链接
 * @param layout 框架
 * @param basePath
 */
function addDynamicRoutes(tRoleLists = [], roleRouteMapping, layout, basePath) {
	roleRouteMapping.forEach(roleRouteItem => {
		tRoleLists.forEach(role => {
			let component = null;
			if (roleRouteItem.fileLocation) {
				if (roleRouteItem.fileLocation === 'ParentView') {
					component = () => import("../layout/ParentView.vue");
				} else {
					component = () => import("../views/" + roleRouteItem.fileLocation + ".vue")
				}
			}

			const rootRouter = {
				path: null,
				name: null,
				component: component,
				query: null,
				meta: {
					icon: null,
					title: null,
					basePath: "",
					uuid: null,
					file: null,
				}
			};
			const l1Children = [];
			if (role === roleRouteItem.shiroKey) {
				rootRouter.path = roleRouteItem.path;
				rootRouter.name = roleRouteItem.title;
				rootRouter.meta.icon = roleRouteItem.icon;
				rootRouter.meta.title = roleRouteItem.title;
				rootRouter.meta.basePath = basePath;
				rootRouter.meta.uuid = roleRouteItem.uuid;
				rootRouter.meta.file = roleRouteItem.fileLocation;
				rootRouter.children = l1Children;
				if (!basePath && rootRouter.path.indexOf("/") === -1) {
					if (roleRouteItem.name === "patientInfo") {
						rootRouter.query = sessionStorage.getItem("userUuid");
					}
					rootRouter.path = `/${rootRouter.path}`;
				}
				if (roleRouteItem.children && roleRouteItem.children.length > 0) {
					let basePathChild = "";
					let nextChildren = rootLayout.children;
					if (roleRouteItem.fileLocation === "ParentView") {
						basePathChild = roleRouteItem.path;
						nextChildren = l1Children;
					}
					addDynamicRoutes(
						tRoleLists,
						roleRouteItem.children,
						nextChildren,
						basePathChild
					);
				}
				layout.push(rootRouter);
			}
		});
	});
}

export default router;
